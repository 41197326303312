<template>
  <div class="item-list">
    <div class="item-list-content d-flex align-center grey lighten-4 pa-2 mb-2 rounded-sm"
         v-for="(item, index) in items" :key="index"
    >
      <v-icon
        color="primary"
        small
        @click="$emit('download', item)"
        class="mr-2"
      >
        $download
      </v-icon>
      <span v-if="!editMode" class="primary--text" @click="changeMode">{{ isFile(item) ? item[itemLabel] : item[itemLabelNoFile] }}</span>
      <v-text-field
        v-else
        v-model="item[itemLabel]"
        outlined
        dense
        single-line
        @blur="editHandle($event, item)"
      ></v-text-field>
      <v-icon
        v-if="remove"
        @click="$emit('remove', item)"
        class="ml-auto pointer"
      >
        mdi-close
      </v-icon>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemList',
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    itemLabel: {
      type: String,
      default: 'name'
    },
    itemLabelNoFile: {
      type: String,
      default: 'description'
    },
    editable: {
      type: Boolean,
      default: false
    },
    remove: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      editMode: false
    }
  },
  methods: {
    changeMode () {
      if (!this.editable) {
        return
      }
      this.editMode = !this.editMode
    },
    isFile (object) {
      return !!object.size
    },
    editHandle (event, item) {
      this.$emit('edit', item)
      this.changeMode()
    }
  }
}
</script>

<template>
  <div class="upload-file-button">
    <ItemList
      v-if="showFileList"
      class="mb-4"
      :items="files"
      @remove="removeFileHandle"
      @download="getFile"
    />
    <v-btn
      color="primary"
      :outlined="outlined"
      :disabled="disabled || loading"
      @click="$refs.uploader.click()"
      small
      depressed
    >
      {{ label }}
    </v-btn>
    <input
      ref="uploader"
      class="d-none"
      type="file"
      :accept="accept"
      :multiple="multiple"
      @change="onFileChanged"
    />
  </div>
</template>

<script>
import ItemList from '@/components/ItemList'
import Service from '@/services'
import { downloadFile, isFile, downloadLocalFile } from '@/utils/helpers'

export default {
  name: 'UploadFileButton',
  components: {
    ItemList
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: true
    },
    accept: {
      type: String,
      default: 'image/'
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      files: []
    }
  },
  watch: {
    items () {
      this.files = this.items
    }
  },
  created () {
    this.files = this.items
  },
  methods: {
    isFile (object) {
      return !!object.size
    },
    onFileChanged (e) {
      this.files = [...this.files, ...Array.from(e.target.files)]
      this.$emit('input', this.files)
    },
    removeFileHandle (file) {
      if (this.isFile(file)) {
        this.files = this.files.filter((item) => item.name !== file.name)
      } else {
        this.files = this.files.filter((item) => item.id !== file.id)
      }
      this.$emit('remove', file)
    },
    async getFile (file) {
      if (isFile(file)) {
        downloadLocalFile(file)
      } else {
        try {
          const { data } = await Service.getFile('/Issue/GetDocumentFile', {
            issueId: this.$route.params.id,
            fileName: file.file.name
          })

          downloadFile(file.file, data)
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
}
</script>

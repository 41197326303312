<template>
  <v-dialog v-model="value" persistent width="500px">
    <v-card class="w-100 rounded-lg">

      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="secondary"
            outlined
            class="ma-2"
            @click="close()"
        >
          {{ $t('common.no') }}
        </v-btn>
        <v-btn
            color="primary"
            class="ma-2"
            @click="confirm()"
        >
          {{ $t('common.yes') }}
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>
